// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import compose from 'recompose/compose'
// import { connect } from 'react-redux'
import filter from 'lodash/filter'
import endsWith from 'lodash/endsWith'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
// import { updateHeaderState } from '../../state/actions'
import Slider from './slider'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Header */
class Header extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()

    this.state = {
      isActive: false,
      whatsActive: 'root',
    }

    this.update = this.update.bind(this)
    this.linkRef = React.createRef()
  }

  /** [update description] */
  update(isActive, whatsActive) {
    this.setState({ isActive, whatsActive })
  }

  /** [render description] */
  render() {
    const { uri, edgesData } = this.props
    const { isActive, whatsActive } = this.state
    const {
      allResources: { edges },
    } = edgesData

    const match = filter(edges, ({ node }) => endsWith(uri, node.routeSlug))

    const altMatch =
      endsWith(uri, 'about') ||
      endsWith(uri, 'about/') ||
      endsWith(uri, 'options') ||
      endsWith(uri, 'options/') ||
      endsWith(uri, 'impressum') ||
      endsWith(uri, 'impressum/') ||
      endsWith(uri, 'contribute') ||
      endsWith(uri, 'contribute/') ||
      endsWith(uri, 'copyright') ||
      endsWith(uri, 'copyright/')

    const isMatch = match.length > 0 || altMatch === true

    return (
      <Fragment>
        {isMatch === true && (
          <Fragment>
            <header>
              <div className="container">
                <div className="buttons">
                  {altMatch === false && (
                    <div className="chapter">
                      <Link
                        className="hidden-when-smaller-than-container"
                        to={match.length > 0 ? match[0].node.routeSlug : '#'}
                        aria-label={
                          match.length > 0 ? match[0].node.title : 'Page'
                        }
                      >
                        {match.length > 0 ? (
                          <span>{match[0].node.title}</span>
                        ) : (
                          <Fragment>
                            <span>&nbsp;</span>
                          </Fragment>
                        )}
                      </Link>
                    </div>
                  )}
                  <div ref={this.linkRef} className="contents">
                    <Link
                      onClick={(e) => {
                        e.preventDefault()
                        if (isActive === false) {
                          this.update(true, 'table-of-contents')
                        }
                        if (isActive === true) {
                          this.update(false, 'root')
                        }
                      }}
                      to="#"
                      aria-label="Contents"
                    >
                      <span>Contents</span>
                    </Link>
                  </div>
                  <Link to="/about" className="about" aria-label="About">
                    <span>About</span>
                  </Link>
                </div>
              </div>
            </header>
            <Slider
              isActive={isActive}
              whatsActive={whatsActive}
              update={this.update}
              linkRef={this.linkRef}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

// // ----------------------------------------------------------------------------
// // ---------------------------------------------------------------------- State
// // ----------------------------------------------------------------------------
// const withState = connect(
//   state => ({
//     headerState: state.headerState,
//     mediaState: state.mediaState,
//   }),
//   dispatch => ({
//     updateHeaderState(payload) {
//       dispatch(updateHeaderState(payload))
//     },
//   })
// )

// // ----------------------------------------------------------------------------
// // -------------------------------------------------------------------- Compose
// // ----------------------------------------------------------------------------
// /** Compose ala FP style */
// const ComposedHeader = compose(
//   withState // Add state
// )(Header)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Header

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import classnames from 'classnames'
import endsWith from 'lodash/endsWith'

import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** makeTree */
const makeTree = (children, uri, pageContext, expanded, expand) => (
  <Fragment>
    {map(children, (child) => (
      <li
        className={classnames({
          active: child.routeSlug.includes(pageContext.routeSlug),
          expanded: child.routeSlug.includes(expanded),
        })}
      >
        {isUndefined(child.children) === true && (
          <Link to={child.routeSlug}>
            <small>{child.title}</small>
          </Link>
        )}
        {isUndefined(child.children) === false && (
          <Fragment>
            {child.children.length !== 0 && (
              <Link
                to={child.routeSlug}
                onClick={(e) => {
                  e.preventDefault()
                  expand(child.routeSlug)
                }}
              >
                <small>{child.title}</small>
              </Link>
            )}
            {child.children.length === 0 && (
              <Link to={child.routeSlug}>
                <small>{child.title}</small>
              </Link>
            )}
          </Fragment>
        )}
        <Fragment>
          {isUndefined(child.children) === false && (
            <Fragment>
              {child.children.length !== 0 && (
                <ul>
                  {makeTree(child.children, uri, pageContext, expanded, expand)}
                </ul>
              )}
            </Fragment>
          )}
        </Fragment>
      </li>
    ))}
  </Fragment>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default makeTree
